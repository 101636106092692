@import '../../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 110;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--colorWhite);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusLarge);

    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 763px;
      flex-grow: 0;
      min-height: auto;
      height: auto;
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
    }
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  /* position: absolute; */
  position: fixed;
  top: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.searchMobileModal {
  &.isOpenInPortal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
  margin: 0;
  width: 12px;
  height: 12px;
}

.closeLight {
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorWhite);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: var(--borderRadiusLarge);
  border-top-left-radius: var(--borderRadiusLarge);
  /* position: fixed;
  left: 0;
  top: 0;
  z-index: 100; */
  background-color: var(--colorWhite);
  width: 100%;
  padding: 16px 24px;
  border-bottom: solid 1px var(--colorGrey100);

  & > h2 {
    font-size: 22px;
    line-height: 120%;
    color: var(--colorBlack);
    width: 100%;
    text-align: center;
  }
  & .close {
    position: relative;
    padding: 0;
  }
}
.mobileModal {
  @media (max-width: 1023px) {
    background-color: var(--colorWhite);
    & .scrollLayer {
      background-color: var(--colorWhite);
      & .focusedDiv,
      & .container {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-top: 67px;
        & .content {
          padding-bottom: 100px;
        }
      }
    }
  }
}

.gallaryModal {
  &.isOpenInPortal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  & .scrollLayer {
    & .container {
      flex-basis: 100%;
      width: 100%;
      max-width: 800px;
      padding: 16px;
      @media (--viewportMedium) {
        padding: 24px;
      }
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: start;
        & .content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          flex-grow: 1;
          & :global(.slick-slider) {
            width: 100%;
          }
        }
      }
      & .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;
        padding: 0;
        padding-bottom: 10px;
        & .close {
          position: relative;
          display: flex;
          justify-content: flex-end;
          padding: 0;
          width: auto;
          order: 2;
        }
        & > h2 {
          order: 1;
          font-size: 20px;
          color: var(--matterColorDark);
          margin: 0;
          line-height: 120%;
          flex-grow: 1;
          @media (--viewportMedium) {
            font-size: 24px;
          }
        }
      }
      & .content {
        position: relative;
        & :global(.slick-slider) {
          position: relative;
        }
        & :global(.slick-dots) {
          position: absolute;
          bottom: 10px;
        }
        & :global(.slick-dots > li) {
          margin: 0;
        }
        & :global(.slick-dots > li > button) {
          width: 8px;
          height: 8px;
          cursor: pointer;
          border-radius: 50%;
          background-color: var(--colorWhite);
          border: none;
          margin: 0 6px 0 0;
        }

        & :global(.slick-dots > li.slick-active > button) {
          background-color: var(--marketplaceColor);
        }
        & :global(.slick-dots > li > button::before) {
          display: none;
        }

        & :global(.slick-arrow) {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          height: 40px;
          display: inline-flex;
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          background-color: var(--colorWhite);
          border: solid 1px var(--colorWhite);
          transition: var(--transitionStyle);
          &:hover {
            background-color: var(--colorGrey50);
            border: solid 1px var(--colorGrey500);
            transition: var(--transitionStyle);
          }
        }
        & :global(.slick-arrow > svg) {
          width: 30px;
          height: 30px;
        }
        & :global(.slick-prev > svg) {
          transform: rotate(0deg);
        }
        & :global(.slick-prev::before),
        & :global(.slick-next::before) {
          display: none;
        }
        & :global(.slick-prev) {
          left: 10px;
        }

        & :global(.slick-next) {
          right: 10px;
        }
        & :global(.AspectRatioWrapper_aspectPadding) {
          padding-bottom: 100% !important;
        }
      }
    }
  }
}

.countryModal {
  & .scrollLayer {
    & .container {
      flex-basis: 1000px;
      width: 100%;
      & .content {
      }
    }
  }
}
