@import '../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filtersWrapper {
  padding-bottom: 90px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  background-color: var(--colorWhite);
  border-top: solid 1px var(--colorGrey100);
  border-bottom-left-radius: var(--borderRadiusLarge);
  border-bottom-right-radius: var(--borderRadiusLarge);
  @media (--viewportMedium) {
    position: absolute;
  }
}

.filterButton {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.resetAllButton {
  composes: filterButton;
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  composes: filterButton;
  color: var(--colorGrey300);
  font-size: 16px;

  /* Layout */
  margin: 0 24px 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.applyButton {
  composes: filterButton;
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
    color: var(--colorWhite);
  }
}
.filtersContent {
  padding: 0 24px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
